import React, { Suspense, lazy } from "react";
import { Route, Switch, Redirect, Router } from "react-router-dom";

const RouteWrapper = lazy(() => import("./../../wrappers/route_wrapper"));
const JotformWrapper = lazy(() => import("./../../wrappers/jotform_wrapper"));
const NanoWrapper = lazy(() => import("./../../wrappers/nano_wrapper"));
const ConsultationChimeRoomWrapper = lazy(() =>
  import("./../../wrappers/consultation_chime_room_wrapper")
);
const SimpleRouterWrapper = lazy(() =>
  import("./../../wrappers/simple_wrapper")
);

import { history } from "@modules/";
import { useSelector } from "react-redux";
import Routes from "@constants/routes";
const FetchUser = lazy(() => import("./fetch_user"));
import LoadingLanding from "./loading_landing";
import { isAuthTokens } from "@helpers/authToken";
const DoctorAvailability = lazy(() =>
  import("@containers/doctor_pages/availability/index")
);

const UserModals = lazy(() => import("@src/components/modals/user_modals"));
const Answer = lazy(() => import("@containers/faq/answer"));
const LandingWrapper = lazy(() => import("./../../wrappers/landing_wrapper"));

const About = lazy(() => import("@containers/static_pages/about"));
const FAQ = lazy(() => import("@containers/faq"));

const Results = lazy(() => import("@containers/faq/list"));
const Contact = lazy(() => import("@containers/static_pages/contact_us"));
const DoctoLayoutStyled = lazy(() => import("@layouts/doctoLayoutStyled"));
const DoctoLayoutStyledNoHeader = lazy(() =>
  import("@layouts/doctoLayoutStyled/no-header")
);

const BrowseHospitalNew = lazy(() =>
  import("@containers/static_pages/browse_hospital_new/index")
);
const ExpertOpinion = lazy(() =>
  import("@containers/static_pages/expert_opinion/index")
);
const ReferAPatient = lazy(() =>
  import("@containers/static_pages/refer_a_patient")
);
const ExecutiveTeam = lazy(() =>
  import("@containers/static_pages/executive_team")
);
const PatientInfo = lazy(() => import("@containers/static_pages/patient_info"));
const PrivacyPolicy = lazy(() =>
  import("@containers/static_pages/privacy_policy")
);
const Assistravel = lazy(() => import("@containers/assistravel"));
const Press = lazy(() => import("@containers/static_pages/press"));
const ConsultDrInfo = lazy(() => import("@containers/static_pages/consult_dr"));
const MedicalCertificate = lazy(() =>
  import("@containers/static_pages/medical_certificate")
);
const SickKids = lazy(() => import("@containers/static_pages/sick_kids"));
const WorkPlace = lazy(() => import("@containers/workplace"));
const ModifyAppointment = lazy(() => import("@containers/modify_appointment"));

const Login = lazy(() => import("@containers/login"));
const ViewFile = lazy(() => import("@containers/download_referral"));
const DocumentAppointmentViewer = lazy(() =>
  import("@containers/documents_appointment_viewer")
);
const AppLayout = lazy(() => import("@layouts/appLayout"));
const AppLayoutStyled = lazy(() => import("@layouts/appLayoutStyled"));
const DoctorAccount = lazy(() => import("@containers/doctor_pages/account"));
const DoctorAppointments = lazy(() =>
  import("@containers/doctor_pages/appointments")
);
const AllAppointments = lazy(() =>
  import("@containers/doctor_pages/appointments/list")
);
const Admin = lazy(() => import("@containers/admin"));
const CompanyAdmin = lazy(() => import("@containers/admin/company-admin"));
const SpecialtyCms = lazy(() => import("@containers/specialties_cms"));
const Business = lazy(() => import("@containers/static_pages/business"));
const MedhealthDashboard = lazy(() =>
  import("@containers/static_pages/medhealth_dashboard")
);
const CompanyLanding = lazy(() => import("@containers/company_landings"));
const NotFound = lazy(() => import("./not_found"));
const AppModals = lazy(() => import("@src/components/modals"));
const Account = lazy(() => import("@containers/account"));
const Billing = lazy(() => import("@containers/account/nurse"));
const Messages = lazy(() => import("@containers/messages"));
const AssignAppointment = lazy(() => import("@containers/assign_appointment"));
const EmergencyAssign = lazy(() => import("@containers/emergency_assign"));
const Jotform = lazy(() => import("@containers/jotform"));
const ApointmentTimeZoneChecker = lazy(() =>
  import("@containers/appointment_time_zone_checker")
);
const SystemCheck = lazy(() => import("@containers/system_check"));

const PatientAppointments = lazy(() =>
  import("@containers/patient_pages/appointments")
);
const PatientAppointmentsLis = lazy(() =>
  import("@containers/patient_pages/appointments/list")
);

const BlogMain = lazy(() => import("@containers/blog"));
const BlogMainFiltered = lazy(() => import("@containers/blog/blog_filtered"));
const BlogPost = lazy(() => import("@containers/blog/blog_post"));

// //video call routesecated"));
// const AppointmentAccess = lazy(() => import("@containers/appointment_access"));
const guestAccess = lazy(() => import("@containers/guest_access"));

const NewChatRoom = lazy(() => import("@containers/chat_room"));
const NurseAccess = lazy(() => import("@containers/emerg_room"));
const NurseAppointments = lazy(() =>
  import("@containers/nurse/appointment_sheets")
);
const InstantAppointments = lazy(() =>
  import("@containers/instant_doctor/appointment_sheets/index")
);
const InstantConsultForm = lazy(() =>
  import("@src/components/modals/instant_consult_modal/index")
);

const ForwardChat = lazy(() => import("@containers/forward"));
//end video call routes

const AcceptReferral = lazy(() => import("@containers/accept_referral"));
const AcceptReferralModal = lazy(() =>
  import("@containers/accept_referral/modal")
);
const AvailabilityWireframe = lazy(() =>
  import("@containers/doctor_pages/availability/wireframe")
);
const Ihi = lazy(() => import("@containers/ihi/ihi_collect"));

const DoctoRoutes = () => {
  const { user, guestUser, fetch } = useSelector((state) => state.session);
  if (fetch || (isAuthTokens(history.location) && !user?.id && !guestUser.id)) {
    return (
      <Suspense fallback={<LoadingLanding />}>
        <FetchUser />
      </Suspense>
    );
  }

  return (
    <Router history={history}>
      <Suspense fallback={<LoadingLanding />}>
        <AppModals />
        {user.id && <UserModals />}
        <Switch>
          <LandingWrapper exact path="/" />
          {/* JOTFORMS */}

          <Route exact path="/login" component={Login} />
          <NanoWrapper exact name="nano" path="/t/:nano" />
          <JotformWrapper
            name="jotform"
            path="/medhealth"
            jotformId="92658250763868"
            component={Jotform}
          />

          <JotformWrapper
            name="jotform"
            path="/AGCC"
            jotformId="92730195903863"
            component={Jotform}
          />
          <RouteWrapper
            layout={DoctoLayoutStyled}
            exact
            path={"/doctor-calendar"}
            component={AcceptReferral}
          />
          <RouteWrapper
            layout={DoctoLayoutStyled}
            exact
            path={"/doctor-calendar-modal"}
            component={AcceptReferralModal}
          />

          <JotformWrapper
            path="/bbwp"
            jotformId="83056480517862"
            component={Jotform}
          />
          <JotformWrapper
            path="/mermaidchempro"
            jotformId="91630055188861"
            component={Jotform}
          />
          <JotformWrapper
            path="/ilsc"
            jotformId="201410659428049"
            component={Jotform}
          />
          {/* END JOTFORMS */}

          {/* Redirect companies */}

          <RouteWrapper
            path="/submit/:appointmentId/ihi"
            layout={DoctoLayoutStyled}
            exact
            component={Ihi}
          />

          <Redirect path="/ahi" to="/companies/ahi" />
          <Route
            path="/hitiq"
            render={({ location }) => {
              return <Redirect to={`/companies/hitiq${location.search}`} />;
            }}
          />
          <Redirect path="/signup/ahi" to="/companies/ahi" />
          <Redirect path="/csgi" to="/companies/csgi" />
          <Redirect path="/wickham" to="/consult-dr-now" />

          {/* <Redirect path="/r2s" to="/companies/r2s" /> */}
          <Redirect
            from="/signup/gold-coast-private-hospital"
            to="/consult-dr-now"
          />
          <Redirect from="/gold-coast-private-hospital" to="/online-doctor" />
          <Redirect
            from="/login/gold-coast-private-hospital"
            to="/online-doctor"
          />
          <Redirect path="/script" to="/online-doctor" />
          <Redirect path="/wickham" to="/online-doctor" />
          <Redirect path="/karratha777" to="/online-doctor" />

          {/**blog */}
          <RouteWrapper
            layout={DoctoLayoutStyled}
            exact
            path={"/blog"}
            component={BlogMain}
          />

          <RouteWrapper
            layout={DoctoLayoutStyled}
            exact
            path={"/blog/search/"}
            component={BlogMainFiltered}
          />
          <RouteWrapper
            layout={DoctoLayoutStyled}
            exact
            path={"/blog/:slug"}
            component={BlogPost}
          />

          {/* EndRedirect companiens */}
          <RouteWrapper
            roles={["admin"]}
            exact
            isPrivate
            name="received-fax"
            path={`/forward-chat/:chatId`}
            component={ForwardChat}
          />
          <RouteWrapper
            roles={["admin"]}
            exact
            isPrivate
            path={`/download-referral-request/:key`}
            name="referral-request"
            component={ViewFile}
          />
          <RouteWrapper
            roles={["admin", "doctor"]}
            exact
            isPrivate
            name="received-fax"
            path={`/referralFiles/:appointmentId`}
            component={ViewFile}
          />
          <RouteWrapper
            roles={["admin", "doctor", "nurse"]}
            exact
            isPrivate
            name="received-fax"
            path={`/documents/:appointmentId`}
            component={DocumentAppointmentViewer}
          />

          <RouteWrapper
            roles={["admin", "doctor"]}
            exact
            isPrivate
            name="received-fax"
            path={`/download-received-fax/:key`}
            component={ViewFile}
          />
          <RouteWrapper
            roles={["admin", "doctor"]}
            exact
            isPrivate
            name="docto-twillio-call-recording"
            path={`/docto-twillio-call-recording/:key`}
            component={ViewFile}
          />
          <RouteWrapper
            path="/not-found"
            layout={DoctoLayoutStyled}
            exact
            component={NotFound}
          />
          <RouteWrapper
            roles={["nurse"]}
            exact
            isPrivate
            path="/billing"
            layout={AppLayoutStyled}
            component={Billing}
          />
          <RouteWrapper
            layout={DoctoLayoutStyled}
            exact
            path={Routes.paths.contact}
            component={Contact}
          />
          <RouteWrapper
            layout={DoctoLayoutStyled}
            exact
            path={"/consult-dr-now"}
            component={ConsultDrInfo}
          />
          <RouteWrapper
            layout={DoctoLayoutStyled}
            exact
            path={"/medical-certificate"}
            component={MedicalCertificate}
          />
          <RouteWrapper
            layout={DoctoLayoutStyled}
            exact
            path={"/paediatrics"}
            component={SickKids}
          />
          <RouteWrapper
            layout={AppLayoutStyled}
            roles={["nurse"]}
            isPrivate
            usePassword
            exact
            path={"/workplace"}
            component={WorkPlace}
          />

          <RouteWrapper
            layout={DoctoLayoutStyled}
            exact
            path={"/kids"}
            component={SickKids}
          />
          <SimpleRouterWrapper
            layout={DoctoLayoutStyled}
            exact
            path={Routes.paths.about}
            component={About}
          />
          <RouteWrapper
            layout={DoctoLayoutStyled}
            exact
            path="/faq/results"
            component={Results}
          />
          <RouteWrapper
            exact
            layout={DoctoLayoutStyled}
            path="/faq/category/:category"
            component={Results}
          />
          <RouteWrapper
            exact
            layout={DoctoLayoutStyled}
            path={"/faq/:slug"}
            component={Answer}
          />

          <RouteWrapper
            layout={DoctoLayoutStyled}
            exact
            path={Routes.paths.faq}
            component={FAQ}
          />

          <RouteWrapper
            layout={DoctoLayoutStyled}
            exact
            path={Routes.paths.businessPage}
            component={Business}
          />
          <Redirect from="/medhealth-dashboard" to="/workplace" />
          <RouteWrapper
            layout={DoctoLayoutStyledNoHeader}
            exact
            path="/medhealth-dashboard"
            component={MedhealthDashboard}
          />
          <RouteWrapper
            layout={DoctoLayoutStyled}
            exact
            path={Routes.paths.referAPatient}
            component={ReferAPatient}
          />
          <RouteWrapper
            layout={DoctoLayoutStyled}
            exact
            path={Routes.paths.executiveTeam}
            component={ExecutiveTeam}
          />
          <RouteWrapper
            layout={DoctoLayoutStyled}
            exact
            path={"/privacy-policy"}
            component={PrivacyPolicy}
          />
          <RouteWrapper
            layout={DoctoLayoutStyled}
            exact
            path={"/patient-info"}
            component={PatientInfo}
          />

          <RouteWrapper
            layout={DoctoLayoutStyled}
            exact
            path={Routes.paths.browseHospital}
            component={BrowseHospitalNew}
          />

          <RouteWrapper
            layout={DoctoLayoutStyled}
            exact
            path={Routes.paths.expertOpinion}
            component={ExpertOpinion}
          />
          <RouteWrapper
            layout={DoctoLayoutStyled}
            exact
            path={Routes.paths.press}
            component={Press}
          />

          {/* APlication ROUTES */}

          {/* public routes */}
          <RouteWrapper
            layout={AppLayout}
            exact
            path="/time-check/:appointmentId"
            component={ApointmentTimeZoneChecker}
          />
          <RouteWrapper
            layout={AppLayout}
            exact
            path="/system-check"
            component={SystemCheck}
          />

          {/* video call access */}

          <Redirect from="/online-doctor" to="/consult-dr-now" />

          <Redirect
            from="/online-doctor/:appointmentId"
            to="/chat-room/:appointmentId"
          />
          <Redirect
            from="/consultation-room/:appointmentId"
            to="/chat-room/:appointmentId"
          />
          <Redirect
            from="/appointments/emergency/:appointmentId"
            to="/chat-room/:appointmentId"
          />
          <Redirect
            from=".consultation-room/:appointmentId"
            to="/chat-room/:appointmentId"
          />
          <Redirect
            from="/room/:username/appointment/:appointmentId"
            to="/chat-room/:appointmentId"
          />

          <RouteWrapper
            layout={AppLayoutStyled}
            routeName="NursingRoom"
            roles={["nurse"]}
            isPrivate
            usePassword
            exact
            path="/emerg-room"
            component={NurseAccess}
          />
          <RouteWrapper
            layout={AppLayoutStyled}
            roles={["nurse"]}
            isPrivate
            usePassword
            exact
            theme="assistravel"
            path="/assistravel"
            component={Assistravel}
          />

          <ConsultationChimeRoomWrapper
            exact
            isPrivate
            path={`/chat-room/:appointmentId`}
            component={NewChatRoom}
          />

          <RouteWrapper
            layout={AppLayout}
            exact
            routeName="guestRoomAccess"
            path="/guest/:appointmentId"
            component={guestAccess}
          />

          <RouteWrapper
            isPrivate
            layout={AppLayout}
            exact
            path={Routes.paths.messages}
            component={Messages}
          />
          <RouteWrapper
            isPrivate
            layout={AppLayoutStyled}
            exact
            path={"/assign/:appointmentId"}
            component={AssignAppointment}
          />
          <RouteWrapper
            isPrivate
            layout={AppLayoutStyled}
            exact
            path={"/emergency-assign/:appointmentId"}
            component={EmergencyAssign}
          />
          <RouteWrapper
            isPrivate
            layout={AppLayout}
            exact
            path={Routes.paths.doctor_profile}
            component={DoctorAccount}
          />
          <RouteWrapper
            isPrivate
            roles={["doctor"]}
            layout={AppLayout}
            exact
            path={"/doctor/availability"}
            component={DoctorAvailability}
          />
          <RouteWrapper
            layout={AppLayoutStyled}
            exact
            path={"/availability-wireframe"}
            component={AvailabilityWireframe}
          />

          <RouteWrapper
            isPrivate
            roles={["doctor"]}
            layout={AppLayout}
            exact
            path={Routes.paths.appointments}
            component={DoctorAppointments}
          />
          <RouteWrapper
            isPrivate
            roles={["patient"]}
            layout={AppLayout}
            exact
            path={"/patient/appointments"}
            component={PatientAppointments}
          />
          <RouteWrapper
            isPrivate
            roles={["doctor"]}
            layout={AppLayout}
            exact
            path="/appointments/list/:type/:page"
            component={AllAppointments}
          />

          <RouteWrapper
            isPrivate
            roles={["patient"]}
            layout={AppLayout}
            exact
            path="/patient/appointments/list/:type/:page"
            component={PatientAppointmentsLis}
          />

          <RouteWrapper
            isPrivate
            layout={AppLayoutStyled}
            exact
            path="/appointments/:appointmentId/modify"
            component={ModifyAppointment}
          />

          <Route path="/admin" component={Admin} />
          <RouteWrapper
            path="/company-admin"
            component={CompanyAdmin}
            layout={AppLayoutStyled}
            roles={["companyAdmin"]}
            isPrivate
          />
          <Route path="/account" component={Account} />
          <Redirect path="/dashboard" to="/account/dashboard" />
          <Route exact path="/companies/:slug" component={CompanyLanding} />
          <RouteWrapper
            layout={AppLayoutStyled}
            roles={["nurse"]}
            isPrivate
            usePassword
            exact
            path={"/nurse/appointments"}
            component={NurseAppointments}
          />
          <RouteWrapper
            layout={AppLayoutStyled}
            roles={["instantDoctor"]}
            isPrivate
            usePassword
            exact
            path={"/instant-consults"}
            component={InstantAppointments}
          />
          <RouteWrapper
            layout={AppLayoutStyled}
            roles={["instantDoctor"]}
            isPrivate
            usePassword
            exact
            path={"/instant"}
            isInline
            component={InstantConsultForm}
          />

          <RouteWrapper
            path="/:slug"
            layout={DoctoLayoutStyled}
            exact
            component={SpecialtyCms}
          />

          <RouteWrapper layout={DoctoLayoutStyled} exact component={NotFound} />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default DoctoRoutes;
